<template>
  <div class="pt-80 pb-10 boxBg">
    <div class="mx-100 my-10 px-30 py-10 border-a bgc-w" style="position:relative;">
      <!-- 返回按钮 -->
      <el-page-header @back="goPage('system')" content="" style="position: absolute;left: 20px;top: 20px;"></el-page-header>
      <!-- 车辆信息 按钮 -->
      <div v-if="$route.name==='carInfo' && hasData" class="slotCss">
        <el-button type="warning" size="small" icon="el-icon-truck"
                   @click="$refs.conRef.goItemPage('carCertification', 'noparams')">车辆认证</el-button>
        <el-button type="success" size="small" icon="el-icon-ship"
                   @click="$refs.conRef.goItemPage('transitionCertification', 'noparams')">过渡认证</el-button>
      </div>
      <!-- 抬头信息 按钮 -->
      <div v-if="$route.name==='headerInfo'" class="slotCss">
        <el-button
            v-if="!$route.query.from"
            type="warning" size="small" icon="el-icon-plus"
            @click="$refs.conRef.handleOpen('noparams')">添加发票抬头</el-button>
      </div>
      <!-- 开票信息 按钮 -->
      <div v-if="$route.path.indexOf('invoiceInfo')>-1" class="slotCss">
        <el-radio-group size="medium" class="mb-10"
                        v-model="activeRadio"
                        @input="changeRadio">
          <el-radio-button v-for="(r, index) in radioList" :key="index"
                           :label="r.name">{{ r.label }}</el-radio-button>
        </el-radio-group>
      </div>
      <el-tabs v-model="activeTab" @tab-click="handleTab">
        <el-tab-pane v-for="(tab, index) in tabList"
                     :key="'ticket'+index"
                     :label="tab.label"
                     :name="tab.name">
        </el-tab-pane>
      </el-tabs>
      <router-view ref="conRef" class="minH"></router-view>
      <keep-alive>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import publicFun from "@/mixins/public";

export default {
  name: "index",
  mixins: [publicFun],
  data() {
    return {
      tabList: [
        { label: '车辆信息', name: 'carInfo' },
        { label: '抬头信息', name: 'headerInfo' },
        { label: '开票信息', name: 'invoiceInfo' },
      ],
      activeTab: 'carInfo',
      radioList: [
        { label: '待开票', name: 'notInvoiceList' },
        { label: '开票成功', name: 'invoiceList' },
        { label: '开票失败', name: 'invoiceList_fail' },
      ],
      activeRadio: 'notInvoiceList',
      hasData: false
    }
  },
  watch: {
    '$route.name': function (val) {
      if (this.$route.params.status === '4') {
        this.activeRadio = 'invoiceList_fail';
      } else {
        this.activeRadio = val;
      }
    },
  },
  mounted() {
    this.activeTab = this.$route.path.split('/')[2];
    if (this.$route.params.status === '4') {
      this.activeRadio = 'invoiceList_fail';
    } else {
      this.activeRadio = this.$route.name;
    }
  },
  methods: {
    handleTab(tab, event) {
      this.goPage(tab.name);
    },
    changeRadio(item) {
      if (this.$refs.conRef) {
        this.activeRadio = item;
        this.$refs.conRef.handleRadio(item);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.boxBg {
  background-image: url("http://47.94.45.53:10009/image/htmlImg/banner1.png");
  background-size: cover;
}
.minH {
  height: calc(100vh - 80px - 48px - 10px - 40px - 56px);
  overflow: hidden;
  position: relative;
}
.el-tabs {
  ::v-deep {
    .el-tabs__item {
      font-size: 18px;
    }
    .el-tabs__header {
      margin-left: 90px;
    }
  }
}
.slotCss {
  z-index: 10;
  position: absolute;
  right: 30px;
}
</style>